/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { Component, useContext } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import Footer from "~components/Footer";
import GetInTouch from "~components/GetInTouch";
import Layout from "~components/Layout";
import SectionHeading from "~components/SectionHeading";
import SEO from "~components/SEO";
import Video from "~components/Video.jsx";

class StudioPageComponent extends Component {
  componentDidMount() {
    const { appContext } = this.props;

    appContext.setLanguageWidgetColor(`black`);
    appContext.setNavColor(`black`);
  }

  componentWillUnmount() {
    const { appContext } = this.props;

    appContext.setLanguageWidgetColor(`white`);
    appContext.setNavColor(`white`);
  }

  //

  splitStringToParagraphs = string => {
    const splitText = string.split(/\r?\n/);
    const jsx = [];

    splitText.forEach((row, index) => {
      const rowIndex = index;

      if (row !== ``) {
        jsx.push(
          <p key={`split-text-${rowIndex}`} className="relative block mb-2">
            {row}
          </p>
        );
      }
    });

    return jsx;
  };

  //

  render() {
    const { documentContext, frontmatter, location } = this.props;
    const { device } = documentContext;

    let videoSource;

    if (frontmatter?.videoUrl) {
      videoSource = frontmatter.videoUrl;
    }

    const { articleImage } = frontmatter;

    return (
      <>
        <SEO
          customTitle={frontmatter.title}
          customDescription={frontmatter.seoDescription}
          customKeywords={frontmatter.seoKeywords}
          path={location.pathname}
        />

        <Layout className="studio-page w-full relative" location={location}>
          <section className="w-full relative bg-off-white">
            <div className="relative grid pt-16 pb-32 xs:pb-6">
              <h1 className="animation-appear-right animation-delay-3 grid-end-19 grid-start-4 sm:grid-end-22 sm:grid-start-2 xs:mt-2 f1">
                {frontmatter.heading}
              </h1>

              <p
                className="animation-appear-right animation-delay-4 grid-end-8 grid-start-17 sm:grid-end-22 sm:grid-start-2 mt-12 b2 whitespace-pre-wrap"
                dangerouslySetInnerHTML={{ __html: frontmatter.intro }}
              ></p>

              {videoSource && (
                <Video
                  className="animation-appear-right animation-delay-5 grid-end-20 grid-start-5 sm:grid-end-22 sm:grid-start-2 mt-16 xs:mt-12 block"
                  src={videoSource}
                />
              )}

              {device === `desktop` && articleImage.childImageSharp && (
                <aside className="animation-appear-right animation-delay-5 grid-end-7 grid-start-6 sm:grid-end-22 sm:grid-start-2 mt-64">
                  <Img
                    fluid={articleImage.childImageSharp.fluid}
                    alt={frontmatter.articleImageAlt || `Article aside`}
                  />
                </aside>
              )}

              <article className="animation-appear-right animation-delay-5 grid-end-8 grid-start-16 sm:grid-end-22 sm:grid-start-2 relative mt-24 xs:mt-16 xs:mb-12">
                <h2 className="f2">{frontmatter.articleHeading}</h2>

                <div className="mt-8 b2 whitespace-pre-wrap">
                  {this.splitStringToParagraphs(frontmatter.articleContent)}
                </div>
              </article>

              {device !== `desktop` && articleImage.childImageSharp && (
                <aside className="animation-appear-right animation-delay-5 grid-end-7 grid-start-6 sm:grid-end-22 sm:grid-start-2 mb-12">
                  <Img
                    fluid={articleImage.childImageSharp.fluid}
                    alt={frontmatter.articleImageAlt || `Article aside`}
                  />
                </aside>
              )}
            </div>
          </section>

          <GetInTouch withNav />

          <section className="w-full relative pb-32 xs:pt-10 bg-pink">
            <SectionHeading text="We’re always on the hunt" />

            <div className="relative grid">
              <h2 className="grid-end-14 md:grid-end-16 sm:grid-end-22 grid-start-4 sm:grid-start-2 mt-6 xs:mt-8 f1">
                {frontmatter.applicationHeading}
              </h2>

              <div className="grid-end-14 grid-start-4 sm:grid-end-22 sm:grid-start-2 mt-24 xs:mt-12">
                <a
                  href={`mailto:${frontmatter.applicationEmail}`}
                  className="studio-page__application flex f2 text-left"
                >
                  <span>{frontmatter.applicationLinkText}</span>
                  <span className="studio-page__application__arrow transition-transform ml-4">
                    →
                  </span>
                </a>
              </div>
            </div>
          </section>

          <Footer />
        </Layout>
      </>
    );
  }
}

//

const StudioPage = ({ data, location }) => {
  const appContext = useContext(AppContext);
  const documentContext = useContext(DocumentContext);
  const { frontmatter } = data.markdownRemark;

  return (
    <StudioPageComponent
      appContext={appContext}
      documentContext={documentContext}
      frontmatter={frontmatter}
      location={location}
    />
  );
};

export default StudioPage;

export const query = graphql`
  query StudioPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        intro
        videoUrl
        applicationEmail
        articleHeading
        articleContent
        articleImage {
          childImageSharp {
            fluid(maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        articleImageAlt
        applicationHeading
        applicationLinkText
        seoDescription
        seoKeywords
      }
    }
  }
`;
